import React from 'react'
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { TypeAnimation } from 'react-type-animation';

const Hero4 = ({ data }) => {

  const titles = data.title;
  const newArray = titles.flatMap(element => [element, 3000]);

  return (
    <>
      <div className='st-height-b125 st-height-lg-b80' id='home'></div>
      <div
        id="home"
        className="st-hero st-style1 st-dynamic-bg st-bg st-fixed-bg"
        style={{ backgroundImage: `url(${data.bgImg})` }}
      >
        <div className="container">
          <div className="st-hero-text st-style1 text-center">
            <h1 className="st-hero-title cd-headline clip cd-headline-style1">
              Ambil kualitas terbaik <br />
              Pemeriksaan untuk<span></span>
              <span className="cd-words-wrapper">
                <span> </span>
                <TypeAnimation sequence={newArray}
                  speed={50}
                  repeat={Infinity} />
              </span>
            </h1>
            <div className="st-hero-btn-group">
              <Link to="https://labs.iniaku.id" target="_blank" className="st-btn st-style1 st-size-medium st-color1 st-smooth-move">
                Login
              </Link>
              <ScrollLink
                to="pemeriksaan"
                className="st-btn st-style1 st-size-medium st-color3 st-smooth-move"
              >
                Pemeriksaan Kami
              </ScrollLink>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Hero4
